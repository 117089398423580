<template>
  <div>
    <v-menu
      :close-on-content-click="false"
      :nudge-right="40"
      min-width="290px"
      offset-y
      transition="scale-transition"
      v-model="showMenu"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :hide-details="hideInputDetails"
          outlined
          :error-messages="errorMessages"
          :label="label"
          prepend-icon="mdi-calendar"
          readonly
          v-model="dateFormatted"
          v-bind="attrs"
          v-on="on"
          :clearable="nullable"
          :disabled="disabled"
        />
      </template>
      <v-date-picker
        v-if="!dateSelected"
        :first-day-of-week="1"
        @input="handleDateInput"
        v-model="pickerDateRaw">
      </v-date-picker>
      <v-time-picker v-if="dateSelected && withTime" v-model="pickerTimeRaw" format="24hr">
        <v-spacer/>
        <v-btn text color="primary" @click="handleTimeInput">
          {{ $t('OK') }}
        </v-btn>
      </v-time-picker>
    </v-menu>
  </div>
</template>
<script>
import {formatDateTime, getDateTime} from '@/utils/dates';

export default {
  props: {
    label: {
      type: String,
      required: false,
      default: () => ''
    },
    value: String,
    errorMessages: {
      type: Array,
      default: () => []
    },
    withTime: {
      type: Boolean,
      required: false,
      default: () => false
    },
    nullable: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hideInputDetails: {
      type: Boolean,
      required: false,
      default: () => true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false
    },
  },
  data() {
    return {
      pickerDateRaw: null,
      pickerTimeRaw: null,
      showMenu: false,
      dateSelected: false,
    };
  },
  computed: {
    dateFormatted: {
      get: function () {
        return this.formatInputValue(this.value);
      },
      set: function (newValue) {
        if (newValue === null) {
          this.closePicker();
        }
      }
    },
  },
  methods: {
    formatDateTime,
    getDateTime,
    handleDateInput() {
      if (this.withTime) {
        this.dateSelected = true;
        return;
      }
      this.$emit('input', getDateTime(this.pickerDateRaw));
      this.closePicker();
      this.pickerDateRaw = null;
    },
    handleTimeInput() {
      if (this.pickerTimeRaw !== null) {
        this.$emit('input', getDateTime(this.pickerDateRaw, this.pickerTimeRaw));
        this.$emit('setInputDateTime', getDateTime(this.pickerDateRaw, this.pickerTimeRaw));
        this.closePicker();
        this.dateSelected = false;
      }
    },
    closePicker() {
      this.showMenu = false;
      this.clearPickerValues()
    },
    clearPickerValues() {
      this.pickerDateRaw = null;
      this.pickerTimeRaw = null;
    },
    setPickerValues(value) {
      if (value) {
        this.pickerDateRaw = formatDateTime(value, 'YYYY-MM-DD')
        this.pickerTimeRaw = formatDateTime(value, 'HH:mm')
      } else {
        this.clearPickerValues();
      }
    },
    formatInputValue(value) {
      let dateTime = value;
      if (this.nullable === false && !value) {
        dateTime = new Date().toISOString().substr(0, 10);
        this.$emit('input', getDateTime(dateTime));
      }
      if (value === null) {
        return '';
      }
      if (this.withTime) {
        return formatDateTime(dateTime, 'DD.MM.YYYY, HH:mm');
      } else {
        return formatDateTime(dateTime, 'L');
      }
    }
  },
  watch: {
    showMenu(newVal, oldVal) {
      if (oldVal && !newVal && this.withTime) {
        this.dateSelected = false;
      }
    },
    value(newValue) {
      this.setPickerValues(newValue);
    }
  },
  created() {
    this.setPickerValues(this.value);
    this.showMenu = false;
  },
};
</script>
