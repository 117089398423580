<template>
  <v-card max-width="860px" class="mt-4 mx-auto">
    <v-card flat class="mt-5">
      <v-card-title v-text="$t('IssueCreate')"/>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="10">
            <v-text-field
              :error-messages="titleErrors"
              :label="$t('title')"
              @blur="$v.item.title.$touch()"
              @input="$v.item.title.$touch()"
              v-model="item.title"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-select
              v-if="backendUserItems"
              :error-messages="assignedToErrors"
              :label="$t('AssignedTo')"
              required
              item-value="@id"
              :items="backendUsers"
              v-model="item.assignedTo"
              @blur="$v.item.assignedTo.$touch()"
              @input="$v.item.assignedTo.$touch()"
              outlined
            >
              <template slot="selection" slot-scope="data">
                <template v-if="data.item.profile">
                  {{ getProfileItemBasics(data.item.profile, 'firstName') }}
                  {{ getProfileItemBasics(data.item.profile, 'lastName') }}
                </template>
                <template v-else>
                  {{ data.item.email }}
                </template>
              </template>
              <template slot="item" slot-scope="data">
                <v-list-item-content>
                  <v-list-item-title>
                    <template v-if="data.item.profile">
                      {{ getProfileItemBasics(data.item.profile, 'firstName') }}
                      {{ getProfileItemBasics(data.item.profile, 'lastName') }}
                    </template>
                    <template v-else>
                      {{ data.item.email }}
                    </template>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-select>
          </v-col>
          <v-col cols="4" sm="4">
            <v-select
              :error-messages="statusErrors"
              :label="$t('Status')"
              required
              :items="statusValues"
              v-model="item.status"
              @blur="$v.item.status.$touch()"
              @input="$v.item.status.$touch()"
              outlined
            />
          </v-col>
          <v-col cols="4" sm="4">
            <v-select
              :error-messages="priorityErrors"
              :label="$t('Priority')"
              required
              :items="priorityValues"
              v-model.number="item.priority"
              @blur="$v.item.priority.$touch()"
              @input="$v.item.priority.$touch()"
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4">
            <InputDate
              :error-messages="startErrors"
              :label="$t('Start')"
              required
              v-model="item.start"
              @blur="$v.item.start.$touch()"
              @input="$v.item.start.$touch()"
              :nullable="true"
              :hide-input-details="false"
            />
          </v-col>
          <v-col cols="4" sm="4">
            <InputDate
              :error-messages="endErrors"
              :label="$t('End')"
              required
              v-model="item.end"
              @blur="$v.item.end.$touch()"
              @input="$v.item.end.$touch()"
              :nullable="true"
              :hide-input-details="false"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <InputEditor
              v-model="item.description"
              :error-messages="descriptionErrors"
              :label="$t('description')"
              :required="true"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p>{{ $t('Attachments') }}:</p>
            <v-list v-if="attachments">
              <v-list-item v-for="attachment in attachments" :key="attachment.id">
                <v-list-item-content>
                  <v-list-item-title>
                    <button @click="getAndDownloadDocument(attachment['@id'])">
                      {{ attachment.name }}
                    </button>
                    <v-icon class="ml-4" color="red lighten-1" @click="deleteAttachment(attachment)">delete</v-icon>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <v-file-input
              :rules="imageRules"
              :label="$t('Attachments')"
              v-model="attachment"
              accept="application/pdf,image/*"
              prepend-icon="mdi-attachment"
              outlined
            />
            <v-btn class="ml-auto" color="primary" @click="uploadAttachment()"
                   :disabled="typeof attachment === 'undefined' || attachment === null"
            >
              {{ $t('Upload') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import {validationMixin} from "vuelidate";
import InputDate from "../InputDate";
import InputEditor from "../InputEditor";
import {date} from "../../validators/date";
import {required} from "vuelidate/lib/validators";
import has from "lodash/has";
import {mapFields} from "vuex-map-fields";
import {mapActions} from "vuex";
import DocumentMixin from "../../mixins/DocumentMixin";
import ProfileMixin from "@/mixins/ProfileMixin";

export default {
  name: "IssueForm",
  mixins: [DocumentMixin, validationMixin, ProfileMixin],
  components: {
    InputDate,
    InputEditor
  },
  props: {
    values: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {
      },
    },

    initialValues: {
      type: Object,
      default: () => {
      },
    },
    uploadAttachment: {
      type: Function,
      required: true
    },
    deleteAttachment: {
      type: Function,
      required: true
    },
    refForm: {
      type: String,
      required: true
    },
  },
  mounted() {
    this.backendUsersGetSelectedItems();
  },
  computed: {
    ...mapFields("backendUser", {
      backendUsers: "selectItems",
    }),
    item() {
      return this.initialValues || this.values;
    },
    backendUserItems() {
      return (this.backendUsers) ? this.backendUsers : null;
    },
    attachments() {
      return this.resolveDocuments(
        this.refForm === 'createForm' ?
          this.item.attachments.map(attachment => attachment.iri) :
          this.item.attachments
      );
    },
    titleErrors() {
      const errors = [];
      if (!this.$v.item.title.$dirty) {
        return errors;
      }

      has(this.violations, "title") && errors.push(this.violations.title);

      !this.$v.item.title.required && errors.push(this.$t("Field is required"));

      return errors;
    },
    assignedToErrors() {
      const errors = [];
      if (!this.$v.item.assignedTo.$dirty) {
        return errors;
      }

      has(this.violations, "assignedTo") &&
      errors.push(this.violations.assignedTo);

      !this.$v.item.assignedTo.required &&
      errors.push(this.$t("Field is required"));

      return errors;
    },
    statusErrors() {
      const errors = [];
      if (!this.$v.item.status.$dirty) {
        return errors;
      }

      has(this.violations, "status") && errors.push(this.violations.status);

      !this.$v.item.status.required &&
      errors.push(this.$t("Field is required"));

      return errors;
    },
    priorityErrors() {
      const errors = [];
      if (!this.$v.item.priority.$dirty) {
        return errors;
      }
      has(this.violations, "priority") && errors.push(this.violations.priority);

      !this.$v.item.priority.required &&
      errors.push(this.$t("Field is required"));

      return errors;
    },
    startErrors() {
      const errors = [];
      if (!this.$v.item.start.$dirty) {
        return errors;
      }

      has(this.violations, "start") && errors.push(this.violations.start);

      return errors;
    },
    endErrors() {
      const errors = [];
      if (!this.$v.item.end.$dirty) {
        return errors;
      }

      has(this.violations, "end") && errors.push(this.violations.end);

      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.item.description.$dirty) {
        return errors;
      }
      has(this.violations, "description") &&
      errors.push(this.violations.description);

      !this.$v.item.description.required &&
      errors.push(this.$t("Field is required"));

      return errors;
    },
  },
  methods: {
    log(message) {
      console.log(message);
    },
    submitItem() {
      if (this.handleSubmit) {
        this.handleSubmit();
      }
    },
    resetItem() {
      if (this.handleReset) {
        this.handleReset();
      }
    },
    ...mapActions({
      backendUsersGetSelectedItems: "backendUser/fetchSelectItems",
    }),
  },
  data() {
    return {
      statusValues: [
        {text: this.$t("New"), value: "new"},
        {text: this.$t("In progress"), value: "inProgress"},
        {text: this.$t("Feedback"), value: "feedback"},
        {text: this.$t("Done"), value: "done"},
      ],
      priorityValues: [
        {text: this.$t("High"), value: 100},
        {text: this.$t("Medium"), value: 50},
        {text: this.$t("Low"), value: 10},
      ],
      imageRules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      attachment: null,
    };
  },
  validations: {
    item: {
      title: {
        required,
      },
      assignedTo: {
        required,
      },
      status: {
        required,
      },
      priority: {
        required,
      },
      description: {
        required,
      },
      start: {
        date,
      },
      end: {
        date,
      },
    },
  },
};
</script>
